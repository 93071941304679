import {initializeApp , setLogLevel} from "firebase/app";
import {getToken, getMessaging, onMessage} from "@firebase/messaging";
import {toast} from "vue3-toastify";
import {h} from "vue";
import PushNotification from "../components/toasts/PushNotification.vue";
import {bool} from "yup";

const firebaseConfig = {
    apiKey: window.appConfig.FIREBASE_API_KEY,
    authDomain: window.appConfig.FIREBASE_AUTH_DOMAIN,
    projectId: window.appConfig.FIREBASE_PROJECT_ID,
    storageBucket: window.appConfig.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window.appConfig.FIREBASE_MESSAGING_SENDER_ID,
    appId: window.appConfig.FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

setLogLevel('silent');
const messaging = getMessaging(app);

const getFirebaseTokenOrRequestPermission = () => {
    return getToken(messaging, {vapidKey: window.appConfig.FIREBASE_VAPID_KEY})
        .then((token) => {
            return Promise.resolve(token);
        })
        .catch((err) => {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                    } else {
                        console.log('Notification permission denied.');
                    }
                });

            return Promise.reject(err);
        });
}

onMessage(messaging, (payload) => {
    let options = {
        autoCLose: Number(payload.data.duration) ?? false,
    };

    toast(h(PushNotification, {
        title: payload.notification.title,
        body: payload.notification.body,
        go_to: payload.data.go_to ?? null,
        timestamp: payload.data.timestamp ?? null,
    }), options);
});

export {getFirebaseTokenOrRequestPermission, messaging};
