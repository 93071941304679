<template>
    <Menu as="div" class="relative inline-block text-left xxs:mt-20 md:mt-0" v-slot="{open}">
        <div>
            <MenuButton class="flex justify-between w-full text-body-6 font-normal text-default-1 items-center">
                <div class="flex flex-row items-center">
                    <img v-if="logo" :src="useImagePathFormatter(organization.slug, logo)" class="mr-2 w-6 h-6 rounded-full"  alt="logo"/>
                    <p class="text-body-5 text-white font-medium">{{organization.name}}</p>
                </div>

                <img v-if="open" src="@/assets/icons/chevron-up.svg" alt="chevron-up"/>
                <img v-else src="@/assets/icons/chevron-down.svg" alt="chevron-down"/>
            </MenuButton>
        </div>
        <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems
                    class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none cursor-pointer">
                <div class="py-1">
                    <div v-if="memberships.length > 0">
                        <MenuItem v-for="membership in memberships" :key="membership.uid" @click="handleOnSelect(membership.organization.slug)">
                            <p
                                @click="changeOrganization(membership.organization.slug)"
                                class="group text-body-5 text-default-1 font-medium flex items-center py-2 pr-5 pl-4 hover:bg-background-3">
                                {{membership.organization.name}}
                            </p>
                        </MenuItem>
                        <hr class="bg-interactive-9 h-px my-3" />
                    </div>
                    <a @click="router.push('/launchpad')"
                       class="group text-body-5 text-default-1 font-medium flex items-center py-2 pr-5 pl-4 hover:bg-background-3">
                        <img src="../../assets/icons/rocket-light.svg" class="h-5 w-5 mr-2.5" alt="rocket"/>
                        View all orgs
                    </a>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>
<script setup>
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {useImagePathFormatter} from "@/composables/useImagePathFormatter";
import {useMemberships} from "@/composables/useMemberships";
import {useAuth} from "@/stores/AuthStore";
import {onMounted, ref} from "vue";
import {useTheme} from "@/stores/ThemeStore";
import router from "@/router";
import {useSubdomain} from "@/composables/useSubdomain";
import {storeToRefs} from "pinia";

const {logo} = storeToRefs(useTheme());
const {getMemberships} = useMemberships();
const {member, init, organization} = storeToRefs(useAuth());

const memberships = ref(null);

onMounted(() => {
    getMemberships(member.value.user_uid, 1, 5).then((response) => {
        memberships.value = response.data.filter((membership) => membership.organization.slug !== organization.value.slug);
    });
});

const changeOrganization = function (slug) {
    const {changeSubdomain} = useSubdomain();

    changeSubdomain(slug, '/');
}

const handleOnSelect = function (slug) {
    const {changeSubdomain} = useSubdomain();

    init(slug).then(() => {
        changeSubdomain(slug, '/profile');
    });
}
</script>
